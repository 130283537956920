<template>
	<el-dialog width="560px" :title="material.id ? '物料信息' : '创建物料'" :visible.sync="show_dialog" :fullscreen="theme.width < 1080" :custom-class="[{fullscreen: theme.width < 1080}]" @closed="clear" :close-on-click-modal="false" append-to-body destroy-on-close>
		<el-tabs style="margin-top: -30px;" v-model="tab_active" v-if="show_dialog">
			<el-tab-pane label="基本信息" name="basic">
				<div style="padding: 0 10px;">
					<el-form ref="create_form" label-width="60px" :model="material" :rules="material_rules" :size="theme.size" status-icon>
						<el-form-item label="名称" prop="name">
							<el-input v-model="material.name" autocomplete="off" placeholder="请输入名称" clearable>
								<el-select slot="prepend" style="width: 120px;" v-if="!admin_user.shop_id" v-model="material.shop_id" placeholder="所属门店" filterable clearable>
									<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
								</el-select>
							</el-input>
						</el-form-item>
						<el-form-item label="类目" prop="categories">
							<el-cascader placeholder="请选择类目" v-model="material.categories" :options="treeCategories" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
						</el-form-item>
						<el-form-item label="品牌" prop="brand_id">
							<el-select v-model="material.brand_id" placeholder="请选择品牌" filterable clearable>
								<el-option label="无" :value="0"></el-option>
								<el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="编码" prop="in_code">
							<el-input v-model="material.in_code" autocomplete="off" placeholder="请输入编码。为空时提交后系统自动生成" :disabled="material.id"></el-input>
						</el-form-item>
						<el-form-item label="条码" prop="bar_code">
							<el-input v-model="material.bar_code" autocomplete="off" placeholder="请输入条码"></el-input>
						</el-form-item>
						<el-form-item label="重量" prop="weight">
							<el-input-number v-model.number="material.weight" placeholder="请输入重量" :step="1" :min="1"></el-input-number> 克
						</el-form-item>
						<el-form-item label="单价" prop="price">
							<el-input-number v-model.number="material.price" placeholder="请输入市场参考单价" :precision="2" :step="0.01" :min="0.01"></el-input-number> 元
						</el-form-item>
						<el-form-item>
							<el-button slot="label" type="text" @click="downloadTemp" v-if="!material.id">下载模板</el-button>
							<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
							<el-upload style="display: inline-block; margin: 0 10px" accept=".xls,.xlsx" :auto-upload="false" :show-file-list="false" :on-change="importMaterials" :action="$api.URI_UPLOADS" :disabled="!$utils.create($api.URI_MATERIALS)" v-if="!material.id">
								<el-button type="primary" :disabled="!$utils.create($api.URI_MATERIALS)" plain>导入物料</el-button>
							</el-upload>
						</el-form-item>
					</el-form>
				</div>
			</el-tab-pane>
			<template v-if="material.id">
				<el-tab-pane label="物料附件" name="attachments">
					<p>物料附件</p>
				</el-tab-pane>
				<el-tab-pane label="物料供应" name="provisions">
					<provisions :material="material" v-if="tab_active === 'provisions'"></provisions>
				</el-tab-pane>
			</template>
		</el-tabs>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import XLSX from "xlsx";
	import provisions from './provisions';
	import { ad_export_json_to_excel_multi_sheet } from '@/utils/exportExcel';

	export default {
		components: {
			provisions
		},
		props: {
			tableTemp: {
				type: Object
			},
			treeCategories: {
				type: Array
			},
			brands: {
				type: Array
			}
		},
		computed: {
			...mapState(['theme', 'admin_user', 'goods']),
			table_temp () {
				const thead = ['名称', '类目ID', '品牌ID', '编码', '条码', '重量', '单价'];
				const filter = ['name', 'categories', 'brand_id', 'in_code', 'bar_code', 'weight', 'price'];
				return {
					data: [],
					sheet: '物料列表',
					thead,
					filter
				};
			}
		},
		methods: {
			async open (material = {}) {
				this.material = { ...material, price: (material.price || 0)/100 };
				this.show_dialog = true;
			},
			handleEdit (r) {
				this.show_dialog = true;
				this.material = {...r};
			},
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getMaterials(this.params);
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】物料吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getMaterials(this.params);
					this.$message.success(msg);
				})
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.name+'】物料吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getMaterials(this.params);
					this.$message.success(msg);
				})
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'create_form':
							this.submitMaterials(this.material, () => {
								if (this.show_dialog) {
									this.show_dialog = false;
								}
								this.$emit('refresh');
							});
						break;
						default:
							this.$message.error("操作未知表单");
						break;
					}
				})
			},
			resetForm (ref) {
				this.show_dialog = false;
				this.$refs[ref].resetFields();
				this.material = {};
			},
			async submitMaterials (data, onClose) {
				const res = await this.$http.post(this.$api.URI_MATERIALS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message({
					type: 'success',
					duration: 1000,
					message,
					onClose
				});
			},
			async getMaterials (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_MATERIALS, {params, headers: {loading}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.materials = result.data;
				this.shops = result.shops;
				this.types = result.types;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			importMaterials ({raw}) {
				const fileReader = new FileReader();
				fileReader.readAsBinaryString(raw);
				fileReader.onload = async ({target}) => {
					try {
						const { filter, thead } = this.table_temp;
						const { Sheets, SheetNames } = XLSX.read(target.result, {type: "binary"});
						const data = XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], {header: filter});
						data.shift();
						if (data.length <= 0) return this.$message.error("无需要导入记录！");
						let index = 0;
						let notify = this.$notify({
							title: '提示',
							dangerouslyUseHTMLString: true,
							message: '<p>正在准备导入物料...</p>',
							showClose: false,
							duration: 0
						});
						for (var i in data) {
							const { name, weight } = data[i];
							if (!name) {
								data[i].status = "提交名称不能为空";
								continue;
							}
							if (!(weight > 0)) {
								data[i].status = "物料重量必须大于0";
								continue;
							}
							index += 1;
							notify.message = '<p>正在导入 ' + index + ' / ' + data.length + '</p>';
							const res = await this.$http.post(this.$api.URI_MATERIALS, {...data[i]});
							const { code, msg } = res.data;
							data[i].status = msg;
							if (code != 0) continue;
						}
						if (index === data.length) {
							notify.message = '<p>导入完成！</p>';
							this.$utils.handleDownload([[...thead, '状态']], [...filter, 'status'], data, "物料导入结果" + this.$moment().format('YYYYMMDDHHmmss'), "导入明细");
							setTimeout(() => {
								notify.close();
							}, 3000);
						}
					} catch (e) {
						console.log(e);
					}
				}
			},
			downloadTemp () {
				const all_categories = this.goods.categories || [];

				const categories = {};
				all_categories.forEach((r) => {
					categories[r.id] = r.name;
					r.parent_name = categories[r.parent_id] || '顶级'; 
				});
				const template = [
					{
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '品牌',
						data: this.brands
					}, {
						filter: ['id', 'name', 'parent_name'],
						thead: ['ID', '名称', '上级'],
						sheet: '分类',
						data: all_categories
					}
				];
				template.unshift(this.table_temp);
				ad_export_json_to_excel_multi_sheet(template, '物料导入模板_生成于' + this.$moment().format('YYYYMMDD'));
			}
		},
		data() {
			return {
				types: [],
				shops: [],
				materials: [],
				attachments: [],
				material: {},
				show_dialog: false,
				tab_active: 'basic',
				material_rules: {
					name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
					weight: [
						{ required: true, message: '请输入重量', trigger: 'blur' },
						{ type: 'number', min: 1, message: '重量最小值为1', trigger: 'blur' }
					]
				},
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getMaterials(this.params, true)
		}
	};
</script>