<template>
	<div style="height: 100%;">
		<main-table>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="名称/编码/条码" clearable>
						<el-button slot="append" icon="el-icon-camera" @click="$refs['js_scan'].open()"></el-button>
					</el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="所属门店" filterable multiple collapse-tags>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-cascader placeholder="请选择类目" v-model="params.categories" :options="tree_categories" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.brands" placeholder="请选择品牌" filterable multiple collapse-tags>
						<el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getMaterials(params)">查询</el-button>
					<el-button type="primary" @click="$refs['show_material'].open()" :disabled="!$utils.create($api.URI_MATERIALS)">添加物料</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="materials" :size="theme.size" @sort-change="sortChange">
				<el-table-column label="创建人" min-width="100" prop="employee_name"></el-table-column>
				<el-table-column label="分类" prop="category_name" min-width="60"></el-table-column>
				<el-table-column label="品牌" prop="brand_name" min-width="60"></el-table-column>
				<el-table-column label="名称" prop="bar_code" min-width="160">
					<template slot-scope="scope">
						<el-link type="primary" @click="$refs['show_material'].open(scope.row)">{{scope.row.name}}</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="in_code" label="编码" min-width="100" sortable="custom"></el-table-column>
				<el-table-column prop="bar_code" label="条码" min-width="100" sortable="custom"></el-table-column>
				<el-table-column prop="weight" label="重量" min-width="80" sortable="custom"></el-table-column>
				<el-table-column prop="price" label="市场单价" min-width="100" sortable="custom" :formatter="(r) => { return (r.price/100).toFixed(2)+' 元'; }"></el-table-column>
				<el-table-column label="供应商家" min-width="70" :formatter="({material_suppliers_count}) => { return material_suppliers_count+' 家'; }"></el-table-column>
				<template v-if="theme.width >= 1420">
					<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
					<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
				</template>
				<el-table-column prop="name" label="操作" width="60">
					<template slot-scope="scope">
						<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete($api.URI_MATERIALS)" v-if="!scope.row.deleted_at">删除</el-button>
						<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore($api.URI_MATERIALS)" v-else>恢复</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getMaterials({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getMaterials({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table>
		<show-material ref="show_material" :brands="brands" :tree-categories="tree_categories" @refresh="getMaterials(params)"></show-material>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTable from '@/components/main-table';
	import showMaterial from './show';

	export default {
		components: {
			mainTable,
			showMaterial
		},
		computed: {
			...mapState(['theme', 'admin_user', 'goods', 'models']),
			tree_categories () {
				return this.$utils.buildTree(this.goods.categories || []);
			},
			brands () {
				return this.goods.brands || [];
			},
			shops () {
				return this.models.shops ? this.models.shops.data : [];
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getMaterials(this.params);
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】物料吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getMaterials(this.params);
					this.$message.success(msg);
				})
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.name+'】物料吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MATERIALS, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getMaterials(this.params);
					this.$message.success(msg);
				})
			},
			async getMaterials (p, l = false) {
				const res = await this.$http.get(this.$api.URI_MATERIALS, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.materials = result.data;
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				materials: [],
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getMaterials(this.params, true)
		}
	};
</script>