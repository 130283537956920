<template>
	<el-dialog width="360px" :title="supply.id ? '更新供应' : '添加供应'" :visible.sync="supply_dialog" :fullscreen="theme.width <= 480" :close-on-click-modal="false" @closed="$refs['supply_form'].resetFields(); supply = {}" append-to-body destroy-on-close>
		<el-form ref="supply_form" label-width="80px" style="max-height: 58vh; padding-right: 10px; overflow: auto;" class="scroll-wrapper" :model="supply" :rules="supply_rules" :size="theme.size">
			<el-form-item label="物料编码" prop="material_no">
				<el-input v-model="supply.material_no" autocomplete="off" placeholder="请输入物料编码" :disabled="supply.material_id" clearable></el-input>
			</el-form-item>
			<el-form-item label="供应商" prop="supplier_id">
				<el-select v-model="supply.supplier_id" placeholder="请选择供应商" style="width: 100%;" filterable clearable>
					<el-option v-for="supplier in suppliers" :key="supplier.id" :label="supplier.name" :value="supplier.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="编码" prop="no">
				<el-input v-model="supply.no" autocomplete="off" placeholder="请输入供应商编码" clearable></el-input>
			</el-form-item>
			<el-form-item label="品级" prop="quality">
				<el-select v-model="supply.quality" placeholder="请选择品级" style="width: 100%;" filterable clearable>
					<el-option v-for="(quality, q) in qualities" :key="q" :label="quality" :value="q"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="单价" prop="price">
				<el-input-number v-model.number="supply.price" placeholder="请输入供应参考单价" :precision="2" :step="0.01" :min="0.01"></el-input-number> 元
			</el-form-item>
			<el-form-item label="备注" prop="remarks">
				<el-input type="textarea" rows="5" v-model="supply.remarks" placeholder="用一段话简单描述备注说明。" maxlength="255" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button slot="label" type="text" @click="downloadTemp" v-if="!supply.id && !supply.material_id">下载模板</el-button>
				<el-button type="primary" @click="submitForm('supply_form')">确 定</el-button>
				<el-upload style="display: inline-block; margin: 0 10px" accept=".xls,.xlsx" :auto-upload="false" :show-file-list="false" :on-change="importSupplies" :action="$api.URI_UPLOADS" :disabled="!$utils.create($api.URI_MATERIALS_SUPPLIERS)" v-if="!supply.id && !supply.material_id">
					<el-button type="primary" :disabled="!$utils.create($api.URI_MATERIALS_SUPPLIERS)" plain>导入供应</el-button>
				</el-upload>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import XLSX from "xlsx";
	import { ad_export_json_to_excel_multi_sheet } from '@/utils/exportExcel';

	export default {
		computed: {
			...mapState(['theme', 'admin_user']),
			table_temp () {
				const thead = ['物料编码', '供应商ID', '供应编码', '供应品级', '供应单价', '备注说明'];
				const filter = ['material_no', 'supplier_id', 'no', 'quality', 'price', 'remarks'];
				return {
					data: [],
					sheet: '线索列表',
					thead,
					filter
				};
			}
		},
		props: {
			qualities: {
				type: Array
			},
			suppliers: {
				type: Array
			}
		},
		methods: {
			async open (supply = {}) {
				this.supply = { ...supply, price: (supply.price || 0)/100 };
				this.supply_dialog = true;
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'supply_form':
							this.submitSupplies(this.supply, () => {
								this.supply_dialog = false;
								this.$emit('refresh');
							});
						break;
						default:
							this.$message.error("操作未知表单");
						break;
					}
				})
			},
			async submitSupplies (data, onClose) {
				const res = await this.$http.post(this.$api.URI_MATERIALS_SUPPLIERS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message({
					type: 'success',
					duration: 1000,
					message,
					onClose
				});
			},
			importSupplies ({raw}) {
				const fileReader = new FileReader();
				fileReader.readAsBinaryString(raw);
				fileReader.onload = async ({target}) => {
					try {
						const { filter, thead } = this.table_temp;
						const { Sheets, SheetNames } = XLSX.read(target.result, {type: "binary"});
						const data = XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], {header: filter});
						data.shift();
						if (data.length <= 0) return this.$message.error("无需要导入记录！");
						let index = 0;
						let notify = this.$notify({
							title: '提示',
							dangerouslyUseHTMLString: true,
							message: '<p>正在准备导入供应...</p>',
							showClose: false,
							duration: 0
						});
						for (var i in data) {
							const { material_no, supplier_id, price } = data[i];
							if (!material_no) {
								data[i].status = "提交物料编码不能为空";
								continue;
							}
							if (!supplier_id) {
								data[i].status = "提供供应商不能为空";
								continue;
							}
							if (!price) {
								data[i].status = "提供单价不能为空";
								continue;
							}
							index += 1;
							notify.message = '<p>正在导入 ' + index + ' / ' + data.length + '</p>';
							const res = await this.$http.post(this.$api.URI_MATERIALS_SUPPLIERS, {...data[i]});
							const { code, msg } = res.data;
							data[i].status = msg;
							if (code != 0) continue;
						}
						if (index === data.length) {
							notify.message = '<p>导入完成！</p>';
							this.$utils.handleDownload([[...thead, '状态']], [...filter, 'status'], data, "供应导入结果" + this.$moment().format('YYYYMMDDHHmmss'), "导入明细");
							this.supply_dialog = false;
							this.$emit('refresh');
							setTimeout(() => {
								notify.close();
							}, 3000);
						}
					} catch (e) {
						console.log(e);
					}
				}
			},
			downloadTemp () {
				const qualities = [];
				for (let id in this.qualities) {
					qualities.push({id, name: this.qualities[id]});
				}
				const template = [
					{
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '品级',
						data: qualities
					}, {
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '供应商',
						data: this.suppliers
					}
				];
				template.unshift(this.table_temp);
				ad_export_json_to_excel_multi_sheet(template, '供应导入模板_生成于' + this.$moment().format('YYYYMMDD'));
			}
		},
		data() {
			return {
				supply: {},
				supply_dialog: false,
				supply_rules: {
					material_no: [{ required: true, message: '必须填写物料编码', trigger: ['blur', 'change'] }],
					supplier_id: [{ required: true, message: '必须选择供应商', trigger: ['blur', 'change'] }],
					price: [{ required: true, message: '必须供应商价格', trigger: ['blur', 'change'] }]
				}
			}
		}
	};
</script>